/* ==========================================================================
    #SUSTAINABILITY
========================================================================== */
.margin-wrapper {
    margin: 30px 0;
    @include mq($from: tablet) {
        margin: 5vw 0;
    }
}
.margin-wrapper--bottom {
    margin-bottom: 30px;
    @include mq($from: tablet) {
        margin-bottom: 5vw;
    }
}

.desktop__flex {
    display: flex;
    flex-direction: column;
    @include mq($from: desktop) {
        flex-direction: row;
    }
}

.sustainability-header-img{
    width: 100%;
    height: 30rem;

    margin-left: auto;
    margin-right: auto;
    margin-top: $inuit-global-spacing-unit-large;
    margin-bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden; 

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @include mq($from: tablet) {
        
            width: 100%;
            height: 25rem;
    
            margin-top: $inuit-global-spacing-unit-large;
    }
    @include mq($from: desktop) {
        height: 25rem;
    }
    
    @include mq($from: wide) {
        height: 30rem;
    }
}

.sustainability__header__block {
    background-color: $brand-primary; 
    width: 100%;
    max-width: 500px;
    padding: 30px;
    transform: translateY(-100px);
    margin-bottom: -100px;
    align-self: flex-start;
    h1, p {
        color: white;
    }
    h1 {
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 0;
    }
    @include mq($from: desktop) {
        width: 450px;
    }
}

.page-content__block {
    width: 100%;
    margin-top: 30px;
    @include mq($from: desktop) {
        margin-left: $inuit-global-spacing-unit-large;
        margin-top: $inuit-global-spacing-unit-large;
        width: calc(100% - 450px);
    }
}