/* ==========================================================================
    #GRIDS
========================================================================== */

.grid__container{
    overflow: hidden;
    margin-bottom: $inuit-global-spacing-unit-large;
}

.grid__innercontainer{
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    margin: 0 -20px -20px 0;
    padding: 0;

    a:active, a:visited, a:focus{
        text-decoration: none !important;
    }

    .grid__item-big, .grid__item-small{
        height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        position: relative;
        overflow: hidden;

        text-decoration: none;
        color: $white;
        background-color: black;

        padding: $inuit-global-spacing-unit-small;


        h3, p{
            margin-bottom: 0px;
            color: $white;
        }

        h3{
            letter-spacing: 1px;
        }

        p{
            font-weight: $font-weight-medium;
        }

        &:empty {
            height: 0;
            border: none;
            padding: 0px;
        }

        .grid__item-contentcontainer{
            position: relative;
            z-index: 1000;
        }

        //OVERLAY
        .grid__item-overlay{
            position: absolute;
            width:100%;
            height: 100%;
            text-align: center;
            z-index: 200;
            left: 0;
            top: 0;
            background: rgb(45,33,52);
            background: linear-gradient(360deg, rgba(45,33,52,0.8) 20%, rgba(45,33,52,0) 100%);
        }

        // IMG
        .grid__item-image{
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
            height: 100%;
            text-align: center;
            z-index: 10;

            display: flex;
            align-items: center;
            justify-content: space-around;
        }

    }

    // BIG COMPONENT
    .grid__item-big{
        // width: 66.2%;
        flex: 1 0 66.2%;
        border-right: 20px solid $white;
        border-bottom: 20px solid $white;

        .grid__item-image{
            align-items: flex-start;
            justify-content: center;
            img{
                min-width: 100%;
                max-width: auto;
                width: 100%;

                min-height: auto;
                height: auto;
                max-height: auto;
            }
        }

        @include mq($from: 415px) {
            .grid__item-image{
                align-items: center;

                img{
                    min-height: auto;
                    max-height: auto;
                    height: auto;

                    min-width: 100%;
                    width: auto;
                    max-width: 100%;
                }
            }
        }
    }

    // SMALL COMPONENT
    .grid__item-small{
        // width: 32.5%;
        flex: 1 0 100%;
        border-right: 20px solid $white;
        border-bottom: 20px solid $white;

        .grid__item-image{
            img{
                min-height: 100%;
                max-height: auto;
                height: 100%;

                min-width: auto;
                width: 100%;
                max-width: auto;
                object-fit: cover;
            }
        }
    }
}

@include mq($from: mobile) {
    .grid__innercontainer{
        .grid__item-small{
            flex: 1 0 50%;
        }
    }
}

@include mq($from: tablet) {
    .grid__innercontainer{
        .grid__item-big, .grid__item-small{
            height: 360px;

            .grid__item-contentcontainer{
                margin-bottom: 0px;
                transition: 0.4s ease-in margin;
            }

            .grid__item-image{
                transform: scale(1);
                transition: 0.5s ease all;
            }

            .grid__item-overlay-red{
                position: absolute;
                width:100%;
                height: 100%;
                text-align: center;
                z-index: 200;
                left: 0;
                top: 0;

                opacity: 0;
                background: linear-gradient(360deg, rgba(219, 55, 50, 0.50) 0%, rgba(219, 55, 50, 0) 100%);
                transition: 0.8s opacity;
            }

            .grid__item-overlay{
                background: linear-gradient(360deg, rgba(45,33,52,0.8) 0%, rgba(45,33,52,0) 100%);
                opacity: 1;
                transition: 0.8s opacity;
            }

            a:hover{
                text-decoration: none;

                .grid__item-image{
                    transform: scale(1.05);
                    transition: 0.8s ease all;
                }

                .grid__item-overlay-red{
                    opacity: 1;
                    background: linear-gradient(360deg, rgba(219, 55, 50, 0.50) 0%, rgba(219, 55, 50, 0) 100%);
                    transition: 0.8s opacity;
                }

                .grid__item-overlay{
                    background: linear-gradient(30deg, rgba(45,33,52,0.8) 20%, rgba(45,33,52,0) 100%);
                    opacity: 0;
                    transition: 0.8s ease opacity;
                }

                .grid__item-contentcontainer{
                    margin-bottom: $inuit-global-spacing-unit-tiny;
                    transition: 0.4s ease-in margin;
                }
            }
        }

        .grid__item-small{
            flex: 1 0 32.5%;
        }
    }

    .about__team__container{
        .grid__innercontainer{
            .grid__item-big, .grid__item-small{
                &:hover{
                    .grid__item-image{
                        transform: scale(1);
                    }

                    .grid__item-overlay-red{
                        opacity: 0;
                    }

                    .grid__item-overlay{
                        background: linear-gradient(360deg, rgba(45,33,52,0.8) 0%, rgba(45,33,52,0) 100%);
                        opacity: 1;
                    }

                    .grid__item-contentcontainer{
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

.about__team__container{
    .grid__item-small{
        background: rgba(0,0,0,0) !important;

        .grid__item-image{
            img{
                min-width: 100%;
                max-width: auto;
                width: 100%;

                min-height: auto;
                height: auto;
                max-height: auto;
            }
        }
    }
}

@include mq($from: tablet) {
    .about__team__container{
        .grid__item-small{
            .grid__item-image{
                img{
                    min-height: 100%;
                    max-height: auto;
                    height: 100%;

                    min-width: auto;
                    width: auto;
                    max-width: auto;
                }
            }
        }
    }
}
/* ==========================================================================
    #FILTERS
========================================================================== */


.work_filter__container{
    ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        margin-left: 0px;
    }

    ul li{
        list-style-type: none;
        margin-left: 0px;
        margin-bottom: $inuit-global-spacing-unit-tiny;
        font-weight: $font-weight-bold;
        letter-spacing: 2px;
        padding: 0px 3px;
        transition: $global-transition-fast;
        z-index: 0;

        position: relative;
        display: inline;

        span{
            z-index: 100;
        }

        &:before{
            display: none;
        }
    }

    .current{
        span{
            background-color: $brand-primary;
            color: $white;
            transition: 0.2s ease all;
        }

    }

    &.categorypage{
        ul li{
            background-color: $brand-primary;
            color: $white;
        }

        a{
            color: $white;
            text-decoration: none;
        }
    }
}

@include mq($from: tablet) {
    .work_filter__container{
        ul{
            display: flex;
            flex-direction: row;
        }

        ul li{
            margin-left: $inuit-global-spacing-unit-small;
            cursor: pointer;

            &:hover{
                background-color: $brand-primary;
                color: $white;
                transition: 0.2s ease all;
                position: relative;

                // &:before{
                //     content: '';
                //     width: 100%;
                //     height: 1rem;
                //     left: 0;
                //     background-color: $brand-primary;
                //     display: inline-block;
                //     position: absolute;
                // }
            }
        }
    }
}

/* ==========================================================================
    #RELATED
========================================================================== */

.detailpage__related__container{
    background-color: white;
    padding-top: $inuit-global-spacing-unit-large;
}
