/* ==========================================================================
    #FOOTER CONTACT
========================================================================== */
.footer-contact__container{
    background-color: $brand-primary;
    padding: $inuit-global-spacing-unit 0px $inuit-global-spacing-unit;

    h1{
        color: $white;
        font-weight: $font-weight-black; 
        margin-bottom: $inuit-global-spacing-unit;
    }

    img{
        max-width: 150px;
    }

    .footer-contact__header{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        img{
            width: 20px;
        }
    }
}

.footer-contact__innercontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    color: $white;

    .footer-contact__innercontainer-group{
        display: flex;
        flex-direction: column;

        margin-top: $inuit-global-spacing-unit-tiny;
    }

    .socials{
        margin-left: 0px;
        margin-top: $inuit-global-spacing-unit;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        order: 3;

        a, a:hover{
            font-size: 1rem;
            margin-right: $inuit-global-spacing-unit-small;
        }
    }

    h4, .socials a {
        font-weight: $font-weight-regular;
        font-size: 12px;
        color: $white;
        text-decoration: none;
        letter-spacing: 2.5px;
    }

    .socials a img{
        width: 25px;
        height: 25px;
    }

    p, h4{
        margin-bottom: 0px;
    }

    h4 {
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: $inuit-global-spacing-unit-tiny;
    }

    p, a, a:active, a:visited, a:hover{
        font-weight:  $font-weight-bold;
        font-size: 20px;
        line-height: 150%;
        color: $white;
        text-decoration: none;
        display: inline-block;
        line-height: 150%;
    }

    .footer-contact__innercontainer-group{
        width: 50%;
        min-width: 150px;
    }

    .lastcontainer{
        display: flex;
        flex-direction: column;
        align-items: space-between;
        height: 100%;
        width: 100% !important;
    }

    .footer-form{
        margin-top: $inuit-global-spacing-unit-small;
    }

    // MAILCHIMPFORM
    .footer-form #mc_embed_signup_scroll{
        display: flex;
        width: 100%;

        input[type="email"]{
            width: 85%; 
            border: none;
            background-color: $white;
            color: $gray;   
            margin-right: 0px;
            padding: 10px;
        }

        input[type="submit"]{
            width: 15%;   
            min-width: 15%; 

            background-color: #B70E1A;
            border: none;
            color: $white !important;
            font-weight: $font-weight-black;

            height: 100%;
            padding-bottom: 0px;
            padding: 10px;
            margin-bottom: 0px !important;
        }

        .mce_inline_error{
            background-color: RGBA(254, 250, 250, 0.80) !important
        }
    }
}

@include mq($from: tablet) {
    .footer-contact__container{
        padding: $inuit-global-spacing-unit-huge 0px;
        
        .footer-contact__header{
            img{
                width: 30px;
            }
        }

        h1{
            margin-bottom: $inuit-global-spacing-unit-large;
        }
    }

    .footer-contact__innercontainer{
        flex-direction: row;

        .footer-contact__innercontainer-group{
            margin-top: 0px;
        }

        h4, .socials a {
            font-size: 16px;
            font-weight: $font-weight-regular;
        }

        p, a, a:active, a:visited, a:hover{
            font-size: 25px;
            
            // position: relative;
            // display: inline;
        }

        a, a:active, a:visited{
            font-weight: $font-weight-medium;
            transition: 0.2s ease-in font-weight;
        }

        a:hover{
            font-weight: $font-weight-bold;
            transition: 0.2s ease-in font-weight;
        }

        .footer-form{
            margin-top: 0px;
        }

        .socials{
            order: 0;
            flex-direction: row;

            margin-top: $inuit-global-spacing-unit;

            a{
                transform: scale(1);
                margin-right: $inuit-global-spacing-unit-small;
                transition: 0.2s ease all;
            }
    
            a:hover{
                transform: scale(1.2);
                transition: 0.2s ease all;
            }
        }

        .footer-contact__innercontainer-group{
            width: 33%;
        }

        .lastcontainer{
            width: 30% !important;
        }
    }

}
/* ==========================================================================
    #NAVIGATION
========================================================================== */


.footer-navigation__container{ 
    background-color: $brand-secondary;
}

.footer-navigation__container .o-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: $inuit-global-spacing-unit-tiny;
    padding-bottom: $inuit-global-spacing-unit-tiny;
    
    a,
    a:visited,
    a:focus,
    a:hover{
        font-size: 12px;
        margin-right: 35px;
        color: $white;
        text-decoration: none;
    }
}

@include mq($from: tablet) {
    .footer-navigation__container .o-wrapper{
        a,
        a:visited,
        a:focus,
        a:hover{
            font-size: 16px;
        }
    }
}

/* ==========================================================================
    #STICKY
========================================================================== */

.footer__sticky__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: $inuit-global-spacing-unit-tiny;
    padding-bottom: $inuit-global-spacing-unit-tiny;
    color: $gray;

    p{
        font-size: 12px;
        margin-bottom: 0px;
    }

    a{
        color: $gray;
    }

    .supermachine{
        display: flex;
        align-items: center;
        
        img{
            width: 30px;
            height: 30px;
        }
    }
}

@include mq($from: tablet) {
    .footer__sticky__container{
        flex-direction: row;
        
        p{
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
}