/*========================================================================== */
/* HAMBURGER
/*========================================================================== */

// <div class="hamburger-container">
//      <div class="hamburger" id="hamburger"> 
//      {# // v-on:click="toggle" :class="{ active: menuOpen }" #}
//      <span class="line"></span>
//      <span class="line"></span>
//      <span class="line"></span>
//  </div>
//</div>

.hamburger-container{
    // padding: 1.3rem;
    // position: fixed;
    z-index: 3000000;
}

.hamburger-container{
	transform: scale(1);
	transition: all 0.3s ease-in-out;
	&:hover{
		transform: scale(1.1);
		transition: all 0.3s ease-in-out;
		.hamburger .line{
			background-color: $black;
        }
    }
    
    .hamburger.active .line{
        background-color: $white;
    }
}

.hamburger{
	transform: scale(0.6);
	transition: transform 0.2s ease;
}

.row .three{
    padding: 80px 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: $black;
    color: $brand-primary;
    text-align: center;
}

.hamburger .line{
    width: 40px;
    height: 5px;
    background-color: $black;
    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.hamburger:hover{
    cursor: pointer;
}
	
.hamburger:active{
        transform: scale(0.5);
        transition: transform 0.2s ease;
}

.navbar__container.homepage .hamburger-container{
    .hamburger .line{
        background-color: $white;
    }
}

.navbar__container.homepage.scroll .hamburger-container{
    .hamburger .line{
        background-color: $black;
    }

    .hamburger.active .line{
        background-color: $white;
    }
}

#hamburger.active .line:nth-child(2){
		opacity: 0;
		transform: scale(0.6);
		transition: transform 0.2s ease;
}

#hamburger.active .line:nth-child(1){
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
}

#hamburger.active .line:nth-child(3){
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
}