/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */


html {
  font-size: ($inuit-global-font-size / 16px) * 1em; /* [1] */
  line-height: $inuit-global-line-height / $inuit-global-font-size; /* [1] */
  overflow-y: scroll; /* [2] */
  min-height: 100%; /* [3] */
  font-family: $font-family-global;
  font-weight:  $font-weight-regular;
  scroll-behavior: smooth;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

body{
  background-color: $white;
}

.center{
  text-align: center;
}

// ERRORPAGE
.errorPage__container{
  padding-bottom: 5rem;

  p, h1{
    width: 100%;
  }
}

@include mq($from: tablet) {
  .errorPage__container{
    p, h1{
      width: 50%;
    }
  }
}