/* ==========================================================================
   ABOUT HEADERCOPY
========================================================================== */

.about__header{
    display: flex;
    flex-direction: column;
    align-items: center;

    p{
        width: 100%;
    }
}
@include mq($from: tablet) {
    .about__header{
        p{
            width: 55%;
        }
    }
}

/* ==========================================================================
    ABOUT HEADER IMAGE
========================================================================== */

.about-header-img{
    width: 98%;
    height: 15rem;

    margin-left: auto;
    margin-right: auto;
    margin-top: $inuit-global-spacing-unit-small;
    margin-bottom: $inuit-global-spacing-unit;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden; 

    img{
        min-height: 100%;
        max-height: auto;
        height: 100%;

        min-width: auto;
        width: auto;
        max-width: auto;
    }
}

@include mq($from: tablet) {
    .about__header{
        flex-direction: row;
    }

    .about-header-img{
        width: 100%;
        height: 20rem;

        margin-top: $inuit-global-spacing-unit-large;
        margin-bottom: $inuit-global-spacing-unit-huge;
    }
}

@include mq($from: desktop) {
    .about-header-img{
        height: 25rem;
    }
}

@include mq($from: wide) {
    .about-header-img{
        height: 30rem;
    }
}

/* ==========================================================================
    SERVICE GRID
========================================================================== */

// .about__services__container{
//     margin-top: $inuit-global-spacing-unit;
// }

.about__services__grid{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: $inuit-global-spacing-unit;

    margin: 0 -20px -20px 0;
    margin-bottom: $inuit-global-spacing-unit;

    .about__services__grid-item{
        display: flex;
        flex-direction: column;

        position: relative;
        overflow: hidden;

        flex: 1 0 100%;

        border-right: 20px solid $white;
        border-bottom: 20px solid $white;

        span{
            color: $brand-primary;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            margin-top: $inuit-global-spacing-unit-small;
            letter-spacing: 1px;

            &:before{
                content: '/ ';
                color: $brand-primary;
            }
        }

        p{
            margin-top: $inuit-global-spacing-unit-small;
        }

        .image__container{
            width: 100%;
            height: 10rem;
            background-color: $brand-primary;

            margin-bottom: $inuit-global-spacing-unit-tiny;

            display: flex;
            align-items: center;
            justify-content: center;

            img{

                min-width: 100%;
                max-width: auto;
                width: 100%;

                min-height: auto;
                height: auto;
                max-height: auto;
            }
        }

    }

    &:empty {
        height: 0;
        border: none;
        padding: 0px;
    }
}

.presskit-text {
    .c-btn--primary {
        display: inline-block;
    }
    @include mq($from: tablet) {
        padding: 30px 0;
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            background-color: #eb2226;
            left: -30px;
            bottom: 0;
            top: 0;
            right: -200px;
            z-index: -1;
        }
    }
}

.presskit-wrapper {
  flex-direction: column-reverse;
  h1 {
    color: white;
  }
}

.presskit-content {
    width:100%;
    padding: 20px;
    position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            background-color: #eb2226;
            left: -30px;
            bottom: 0;
            top: -50px;
            right: -200px;
            z-index: -1;
        }
    @include mq($from: tablet) {
        &::after {
            display: none;
        }
    }
}

@include mq($from: mobile) {
    .about__services__grid{
        .about__services__grid-item{
            flex: 1 0 50%;
        }
    }
}

@include mq($from: tablet) {
    // .about__services__container{
    //     margin-top: 10rem;
    // }

    .about__services__grid{
        .about__services__grid-item{
            flex: 1 0 50%;
        }
    }
    .floating-background {
      width: 80%;
      top: 130px;
    }
    .presskit-wrapper {
      flex-direction: row;
      h1 {
        color: black;
      }
    }
    .presskit-content{
        width:100%;
        padding:0 30px 0 50px;
    }
}

@include mq($from: desktop) {
    // .about__services__container{
    //     margin-top: 15rem;
    // }

    .about__services__grid{
        .about__services__grid-item{
            flex: 1 0 30%;
        }
    }
}
