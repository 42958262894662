/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

// We have all of our heading font sizes defined here. Passing these pixel
// values into our `inuit-font-size()` mixin will generate a rem-based
// `font-size` with a pixel fallback, as well as generating a `line-height` that
// will sit on our baseline grid.

$inuit-font-size-h1:  60px !default;
$inuit-font-size-h2:  40px !default;
$inuit-font-size-h3:  22px !default;
$inuit-font-size-h4:  16px !default;
$inuit-font-size-h5:  18px !default;
$inuit-font-size-h6:  16px !default;


::selection {
  background: $brand-primary;
  color: $white;
}

//
// COPY
//

p{
  font-size: 16px;
  line-height: 180%;
  margin-bottom: $inuit-global-spacing-unit-small;
}

p.big, .big, .big p{
  font-size: 18px;
}

@include mq($from: mobile) {
    p.big, .big, .big p{
      font-size: 110%;
    }
}

@include mq($from: tablet) {
  p.big, .big, .big p{
    font-size: 122%;
  }
}

//
// HEADERS
//

h1, h2, h3, h4{
  line-height: 120%;
  margin-bottom: $inuit-global-spacing-unit;
}

// H1
h1 {
  font-size: $inuit-font-size-h1 - 25px;
  line-height: 100%;
  font-family: $font-family-heading;
  font-weight:  $font-weight-black;
  letter-spacing: 3px;
}

@include mq($from: tablet) {
  h1 {
    font-size: $inuit-font-size-h1;
  }
}

// H2
h2 {
  font-size: $inuit-font-size-h2  - 10px;
  font-family: $font-family-heading;
  font-weight:  $font-weight-bold;

  // padding-bottom: $inuit-global-spacing-unit;
}

@include mq($from: tablet) {
  h2 {
    font-size: $inuit-font-size-h2;
  }
}


// H3
h3 {
    @include inuit-font-size($inuit-font-size-h3);
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    position: relative;
}

h4 {
  @include inuit-font-size($inuit-font-size-h4);
  font-family:  $font-family-global;
  font-weight:   $font-weight-bold;
  margin-bottom: $inuit-global-spacing-unit-tiny;
}


/* ==========================================================================
   #LISTS
   ========================================================================== */

  // .content__list{
  //   margin-left: 0px;
  // }

  // .content__list li{
  //   margin-left: 20px;
  //   position: relative;
  //   list-style-type: none;

  //     &:before{
  //       content: '';
  //       display: inline-block;
  //       left: -25px;
  //       bottom: 2px;
  //       position: absolute;
  //       width: 15px;
  //       height: 15px;
  //       background-image: url('../img/list-icon.svg');
  //       background-repeat: no-repeat;
  //       background-size: contain;
  //       background-position: center;
  //     }
  // }

  ul{
    margin-left: 0px;
  }

  ul li{
    margin-left: 20px;
    position: relative;
    list-style-type: none;
    font-size: 16px;

      &:before{
        content: '';
        display: inline-block;
        left: -25px;
        top: 5px;
        position: absolute;
        width: 15px; 
        height: 15px;
        background-image: url('../../img/list-icon.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
  }
