
.detailpage__content__container{
    padding-bottom: $inuit-global-spacing-unit-small;
    border-bottom: 1px solid $brand-primary;
}

@include mq($from: tablet) {
    .detailpage__content__container{
        padding-bottom: $inuit-global-spacing-unit-huge;
    }
}

/* ==========================================================================
    #DETAILPAGE HEADER
========================================================================== */

.detailpage__headerimage__container{
    width: 100%;
    margin-top: $inuit-global-spacing-unit-small;
    margin-bottom: $inuit-global-spacing-unit-small;

    img{
        position: abolute;
    }
}

@include mq($from: tablet) {
    .detailpage__headerimage__container{
        margin-top: $inuit-global-spacing-unit-large;
        margin-bottom: $inuit-global-spacing-unit-large;
    }
}

/* ==========================================================================
    #DETAILPAGE COMPONENTS - HEADERS
========================================================================== */
// .detailpage__header__container{
//     display: flex;
//     flex-flow: column wrap;
//     align-items: space-between;

//     margin-top: $inuit-global-spacing-unit-small;
//     margin-bottom: $inuit-global-spacing-unit-small;

//     .detailpage__header__subheading{
//         flex: 1 0 33%;
//         border-right: 20px solid $white;

//         p{
//             color: $brand-primary;
//             font-weight: $font-weight-regular;
//             text-transform: uppercase;
//             letter-spacing: 1px;
//             margin-bottom: $inuit-global-spacing-unit-tiny;

//             &:before{
//                 content: '/ ';
//                 color: $brand-primary;
//             }
//         }
//     }

//     .detailpage__header__header{
//         flex: 1 0 66%;

//         h2{
//             margin-bottom: 0px;
//         }
//     }
// }

// @include mq($from: mobile) {
//     .detailpage__header__container{
//         flex-flow: row wrap;
//     }
// }
.detailpage__columns__innercontainer{
    h2{
        margin-bottom: 0px;
        padding-right: $inuit-global-spacing-unit-small;
    }

    p{
        margin-bottom: 0px;
        padding-right: $inuit-global-spacing-unit-small;
    }

    .detailpage__header__subheading{
        color: $brand-primary;
        font-weight: $font-weight-regular;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: $inuit-global-spacing-unit-tiny;

        &:before{
            content: '/ ';
            color: $brand-primary;
        }
    }
}

/* ==========================================================================
    #DETAILPAGE COMPONENTS - IMAGE/VIDEO
========================================================================== */

.detailpage__image__container, .detailpage__video__container{
    margin-bottom: $inuit-global-spacing-unit-small;

    .video__container{
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .video__container-no-iframe {
        video {
            width: 100%;
        }
    }
}

/* ==========================================================================
    #DETAILPAGE COMPONENTS - QUOTES
========================================================================== */
.detailpage__columns__innercontainer{
    .detailpage__quote__container{
        width: 100%;
        min-width: 300px;

        font-weight: $font-weight-black;
        font-size: 30px;
        color: $brand-primary;
        
        margin-top: $inuit-global-spacing-unit-small;

        .detailpage__quote__innercontainer{
            // max-width: 85%;
            position: relative;
            padding-top: 10px;
            padding-right: $inuit-global-spacing-unit-small;

            p{
                padding-top: 10px;
            }

            &:before{
                content: '';
                position: absolute;
                display: inline-block;
                width: 95%;
                height:8px;
                top: 0px;
                background-color: $brand-primary;;
            }
        }

        span{
            font-weight: $font-weight-regular;
            color: $gray;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}

@include mq($from: tablet) {
    .detailpage__columns__innercontainer{
        .detailpage__quote__container{
            margin-top: -10px;
        }
    }
}


/* ==========================================================================
    #DETAILPAGE COLUMNS
========================================================================== */
// ALL COLUMNS
.detailpage__columns__innercontainer{
    padding: 0px;
    padding-bottom: $inuit-global-spacing-unit-tiny;
}

// TWO COLUMN
.detailpage__twocolumn__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;

    &.small-big{
        .detailpage__twocolumn__container__innercontainer:first-child{
            width: 100%;
        }
    
        .detailpage__twocolumn__container__innercontainer:last-child{
            width: 100%;
        }
    }

    &.big-small{
        .detailpage__twocolumn__container__innercontainer:first-child{
            width: 100%;
        }
    
        .detailpage__twocolumn__container__innercontainer:last-child{
            width: 100%;
        }
    }
}

// THREE COLUMN
.detailpage__threecolumn__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;

    .detailpage__threecolumn__container__innercontainer{
        width: 100%;
    }
}

@include mq($from: tablet) {
    // TWO COLUMN
    .detailpage__twocolumn__container{
        flex-direction: row;
        margin-bottom: $inuit-global-spacing-unit-small;

        &.small-big{
            .detailpage__twocolumn__container__innercontainer:first-child{
                width: 33%;
            }
        
            .detailpage__twocolumn__container__innercontainer:last-child{
                width: 66%;
            }
        }

        &.big-small{
            .detailpage__twocolumn__container__innercontainer:first-child{
                width: 66%;
            }
        
            .detailpage__twocolumn__container__innercontainer:last-child{
                width: 33%;
            }
        }
    }


    // THREE COLUMN
    .detailpage__threecolumn__container{
        flex-direction: row;
        margin-bottom: $inuit-global-spacing-unit-small;

        .detailpage__threecolumn__container__innercontainer{
            width: 32.8%;
        }
    }

/* ==========================================================================
    #DETAILPAGE COMPONENTS - SPACER
========================================================================== */

    .spacer-desktop, .spacer-tablet{
        display: none;
    }

    .spacer-mobile{
        display: block;
    }

    @include mq($from: tablet) {
        .spacer-tablet{
            display: block;
        }
        
        .spacer-mobile, .spacer-desktop{
            display: none;
        }
    }

    @include mq($from: desktop) {
        .spacer-desktop{
            display: block;
        }
        
        .spacer-mobile, .spacer-tablet{
            display: none;
        }
    }

}