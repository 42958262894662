/* ==========================================================================
   #TABLE
   ========================================================================== */

/**
 * A simple object for manipulating the structure of HTML `table`s.
 */

.o-table {
  width: 100%;
}





/* Equal-width table cells
   ========================================================================== */

/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */

.o-table--fixed {
  table-layout: fixed;
}





/* Size variants
   ========================================================================== */

.o-table--tiny {

  th,
  td {
    padding: $inuit-global-spacing-unit-tiny;
  }

}


.o-table--small {

  th,
  td {
    padding: $inuit-global-spacing-unit-small;
  }

}


.o-table--large {

  th,
  td {
    padding: $inuit-global-spacing-unit-large;
  }

}


.o-table--huge {

  th,
  td {
    padding: $inuit-global-spacing-unit-huge;
  }

}
