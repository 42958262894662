/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

$inuit-wrapper-width: 1300px !default;

@if (type-of($inuit-wrapper-width) != number) {
  @error "`#{$inuit-wrapper-width}` needs to be a number.";
}

.o-wrapper {
  @include inuit-clearfix();
  padding-right: $inuit-global-spacing-unit-tiny;
  padding-left:  $inuit-global-spacing-unit-tiny;
  margin-right: auto;
  margin-left:  auto;
  max-width: $inuit-wrapper-width;
  position: relative;
}

@include mq($from: tablet) {

  .o-wrapper {
      padding-right: $inuit-global-spacing-unit;
      padding-left:  $inuit-global-spacing-unit;
      max-width: $inuit-wrapper-width;
  }

  .o-wrapper:after{
    display: none !important;
  }
}

.content__container{
  margin-top: $inuit-global-spacing-unit-large;
}
