/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  transition: $global-transition;
  border-radius: $global-radius;

  font-family:  $font-family-heading;
  font-weight:  $font-weight-medium;
  text-decoration: none;
}

.buttonContainer{
  margin-bottom: $inuit-global-spacing-unit-large;
}





/* ==========================================================================
   #BUTTON PRIMARY
   ========================================================================== */

.c-btn--primary {
  border: 1px solid $brand-primary;
  color: $brand-primary !important;
  padding: 10px 30px;
  background-color: white;
  text-transform: uppercase;
  font-weight:  $font-weight-black !important;
  transition:  $global-transition-fast;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
  }

  &:hover{
    background-color: $brand-primary;
    color: $white !important;
    transition:  $global-transition-fast;
  }

}

@include mq($from: tablet) {
  .c-btn--primary {
    cursor: pointer;
  }
}


/* ==========================================================================
    #BUTTON SECONDARY
========================================================================== */

  .c-btn--secondary {
    background-color: $brand-primary;
    border: none;
    color: $white;
    font-weight:  $font-weight-black !important;
    position: relative;

    padding: 0px 5px;
    
    &:hover,
    &:active,
    &:focus {
      text-decoration: none; /* [4] */
    }

    // ICONS

    // ARROW
    span.icon-arrow{
      position: relative;
      display: inline-block;

      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 8px solid $white;
      border-bottom: 5px solid transparent;

      margin-left: 5px;
      transition: 0.5s ease all;
    }

    &:hover{
      span.icon-arrow{
        margin-left: 10px;
        transition: 0.5s ease all;
      }
    }

  }

  @include mq($from: tablet) {

    .c-btn--secondary {
      cursor: pointer;
    }
  }
  


/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
}

.c-btn--large {
  padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
}

/* ==========================================================================
    #INLINE
========================================================================== */
.inline-button, p a{
    position: relative;
    color: $black;
    text-decoration: none;
    margin-left: 5px;

    &:hover{
      text-decoration: none;
      color: $brand-primary;
    }

    &:before{
        content: '';
        position: absolute;
        display: inline-block;
        width: 100%;
        bottom: 0px;
        left: 0;
        background-color: $gray;
        height: 1px;
    }

    &:after{
      content: '';
      position: absolute;
      display: inline-block;
      width: 0%;
      bottom: 0px;
      left: 0;
      height: 2px;
      transition: 0.5s ease all;
    }

    &:hover:after{
        width: 100%;
        background-color: $brand-primary;
        height: 2px;
        transition: 0.5s ease all;
    }
}

@include mq($from: tablet) {
  .inline-button, p a{
    &:hover{
      text-decoration: none;
      color: $brand-primary;
    }

    &:hover:after{
        width: 100%;
        background-color: $brand-primary;
        height: 2px;
        transition: 0.5s ease all;
    }
  }
}

// SUPERMACHINE BUTTON
@include mq($from: tablet) {
  .supermachine__link{
    &:hover{
      color: #0241EC;
    }
    &:hover:after{
      background-color: #0241EC !important;
    }
  }
}