/* ==========================================================================
    #JOBS
========================================================================== */

.jobs__content__container{
    margin-bottom: $inuit-global-spacing-unit-huge;
}

/* ==========================================================================
    #JOBS HEADER
========================================================================== */

.jobs__content-item{
    width: 100%;
    position: relative;
    // border-bottom: 0.5px solid $brand-primary;
    
    // JOBS HEADER
    .jobs__content-item__header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-top: 0.5px solid $brand-primary;

        p{
            font-size: 25px;
            margin-bottom: 0px;
            padding: 20px 0px;
        }

        strong{
            margin-right: $inuit-global-spacing-unit-small;
            font-weight: $font-weight-black;
        }

        span{
            margin-left: $inuit-global-spacing-unit-tiny;
            color: $brand-primary;
        }
    }

    &:last-child{
        border-bottom: 0.5px solid $brand-primary;
    }
}

@include mq($from: tablet) {
    .jobs__content-item{
        // JOBS HEADER
        .jobs__content-item__header{
            p{
                font-size: 40px;
            }
        }
    }
}


/* ==========================================================================
    #JOBS BODY
========================================================================== */

.jobs__content-item__body{
    max-width: 85%;
    margin-left: 9%;
    // padding-bottom: $inuit-global-spacing-unit;

    height: 0px;
    overflow: hidden;
    transition: 0.5s ease height;

    p{
        margin-bottom: $inuit-global-spacing-unit-small;
    }

    .jobs__content-item__linkcontainer{
        margin-top: $inuit-global-spacing-unit;
    }

    .image__container{
        margin-bottom: $inuit-global-spacing-unit-small;
    }
}

// show/hide buttons
.c-btn--secondary.less{
    display: none;
}
.c-btn--secondary.more{
    display: block;
}

.jobs__content-item__button{
    display: inline-block;
    position: absolute;

    top: calc(100% - 24px);
    right: 0;
}

// OPEN/CLOSE item
.jobs__content-item.active{
    .jobs__content-item__body{
        padding-bottom: $inuit-global-spacing-unit-huge;
        height: auto;
        transition: 0.5s ease height;
    }

    // show/hide buttons
    .c-btn--secondary.less{
        display: block;
    }
    .c-btn--secondary.more{
        display: none;
    }
}

@include mq($from: tablet) {
    .jobs__content-item__body{
        max-width: 60%;
        margin-left: 9%;
    }
}