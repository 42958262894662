/* ==========================================================================
   #COOKIEBAR
   ========================================================================== */
.cookiebar__container{
    position: fixed;
    z-index: 99999999999;
    left: 0%;
    bottom: 0;
    transform: translateY(100%);
    max-width: 100%;

    transition: 0.5s ease all;

    .cookiebar__innercontainer{
        background-color: $brand-primary;
        color: $white;
        padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
    
        display: flex;
        align-items: flex-end;
    }

    p{
        margin-bottom: 0px;
        font-size: 14px;
    }

    p a, p a:hover{
        // text-decoration: underline;
        color: $white;

        &:before{
            background-color: $white !important;
        }
        &:after{
            background-color: $white !important;
        }
        &:hover{
            color: $white;
        }
    }
    
    .c-btn--secondary{
        background-color: $brand-secondary;
        min-width: 50px;
    }

    &.show{
        left: 0%;
        bottom: 0%;
        transition: 0.5s ease all;
    }
}

@include mq($from: tablet) {
    .cookiebar__container{
        max-width: 30%;
    }
}