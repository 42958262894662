/* ==========================================================================
    #IMAGECONTAINER
========================================================================== */
.image__container{
    position: relative;
    height: auto;
    min-height: 20px;
    max-height: auto;
    overflow: hidden;
    
    // margin-top: $inuit-global-spacing-unit-small;
    // margin-bottom: $inuit-global-spacing-unit-small;

    img{
        width: 100%;
        min-height: 10px;
        max-height: auto;
        height: 100%;

        &:hover {
            cursor: pointer;
        }
    }
}

/* ==========================================================================
    #VIDEOCONTAINER
========================================================================== */
.video__container{
    padding:56.25% 0 0 0;
    position:relative;
    
    margin-top: $inuit-global-spacing-unit-small;
    margin-bottom: $inuit-global-spacing-unit-small;

    iframe {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }

    video {
        max-width: 100%;
        width: 100%;
    }
}

/* ==========================================================================
    #IMAGEOVERLAY
========================================================================== */

.image-overlay{
    width: 100vw;
    background-color: rgba(186,12,15,0.8);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
	z-index: 90000000000; 
	
	margin: auto;

    display: none;
    align-items: center;
    justify-content: center;

    .image-content img{
        width: 100%; 
		min-height: 20rem;
		display: none;
    }
}

.image-overlay.hide{
    display: none;
}

@include mq($from: tablet) {
	.image-overlay{
        display: flex;
		.image-content img{
            display: initial;
			width: 100%; 
			min-height: 20rem;
		}
	}
}

@include mq($from: desktop) {
	.image-overlay{
		.image-content img{
			width: 60rem; 
			height: 39rem;
			display:flex;
		}
	}
}

.close-button-container{
    position: absolute;
    top: 1.3rem;
      right: 2.2rem;
      width: 1.5rem;
      height: 1.5rem;
      padding-top: 1rem;
  
      cursor: pointer;
      transform: scale(0.7);
      transition: all 0.2s ease;
  
      &:hover{
          transform: scale(0.8);
          transition: all 0.2s ease;
      }
  }