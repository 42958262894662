/*========================================================================== */
/* NAVIGATION
/*========================================================================== */
.navbar__container{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 4rem;
    padding: 1px;
    display: flex;
    border-top: 8px solid $brand-primary;
    border-bottom: 0px solid $brand-primary;
    // transition: 0.5s ease border;
    transition: 0.5s ease border-top;
    z-index: $zindex-tooltip;

    // GENERAL
    .o-wrapper{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    // LOGO
    img{
        width: 125px;
        height: 35px;

        margin-top: 0.5rem;
    }

    .navbar__logo.color{
        display: block;
    }

    .navbar__logo.white{
        display: none;
    }
    
    // NAVIGATION
    .navbar__innercontainer{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0px;
        width: 100%;

        a, a:hover, a:active, a:focus{
            color: $black;
            text-decoration: none;
            font-family: $font-family-global;
            font-weight: $font-weight-medium;
            font-size: 14px;
            letter-spacing: 1px;
            // margin-left: 2rem;
            position: relative;
            display: inline-block;
            z-index: $zindex-tooltip;

            margin-top: 10px;

            &:after{
                content: '';
                position: absolute;
                width: 0px;
                height: 0px;
                transform: scale(0);
                display: inline-block;
                bottom: 0;
                top: 25px;
                left: 0;
                background-color: $brand-primary;
            }
        }

        a.active{
            font-weight: $font-weight-black;
        }

        a:hover:after{
            transform: scale(1);
            transition:  $global-transition-fast;
        }

        a.active:after{
            transform: scale(1);
            transition:  $global-transition-fast;
        }
    }

    // EVENTBUTTON
    .eventButton{
        border: 1px solid $brand-primary;
        color: $brand-primary !important;
        padding: 3px 10px;
        background-color: white;
        text-transform: uppercase;
        font-weight:  $font-weight-black !important;
        transition:  $global-transition-fast;
        display: inline-block;
        margin-top: $inuit-global-spacing-unit !important;
        max-width: 90%;
        text-align: center;
    }

    #desktop-navigation{display: none;}

    #mobile-navigation{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        visibility: visible;
    }
}

.navbar__container.scroll{
    background-color: white;
    border-top: 0px solid $brand-primary;
    border-bottom: 1px solid $brand-primary;
    height: 3rem;
    // height: 4rem;
    transition: 0.5s ease border-top;
    
    // LOGO
    // .navbar__logo.color{
    //     display: block;
    // }

    // .navbar__logo.white{
    //     display: none;
    // }

    a, a:hover, a:active, a:focus{
        color: $black;
    }
}

@include mq($from: tablet) {
    .navbar__container{
        img{
            width: 160px;
            height: 50px;
            margin-top: 0.5rem;
        }

        .navbar__innercontainer{
            justify-content: flex-end;
            align-items: center;

            a, a:hover, a:active, a:focus{
                &:after{
                    width: 100%;
                    height: 5px;
                }
            }

        }
        #desktop-navigation{display: inline-block;}
        #mobile-navigation{display: none; visibility: hidden;}
    }

    .navbar__container{
        // EVENTBUTTON
        .eventButton{
            margin-top: 0px !important;

            &:hover{
                background-color: $brand-primary;
                color: $white !important;
                transition:  $global-transition-fast;

                &:after{
                    display: none !important;
                    width: 0px !important;
                }
            }

        }

        // NAVIGATION
        .navbar__innercontainer{
            a, a:hover, a:active, a:focus{
                margin-left: 0.5rem;
            }
        }
    }

    .navbar__container.scroll{
        height: 4rem;

        img{
            width: 160px;
            height: 50px;
            margin-top: 0.5rem;
        }
    }
}

@include mq($from: desktop) {
    .navbar__container{
        // NAVIGATION
        .navbar__innercontainer{
            a, a:hover, a:active, a:focus{
                margin-left: 2rem;
            }
        }
    }
}


//
// HOME NAVIGATION
// 

.navbar__container.homepage{
    .navbar__logo.color{
        display: none;
    }

    .navbar__logo.white{
        display: block;
    }

    .navbar__innercontainer{
        a, a:hover, a:active, a:focus{
            color: $white;
        }
    }

    // EVENTBUTTON
    .eventButton{
        border: 1px solid $white;
        color: $white !important;
        background-color: rgba(255,255,255,0);
    }
}

.navbar__container.homepage.scroll{
    a, a:hover, a:active, a:focus{
        color: $black;
    }

    .navbar__logo.color{
        display: block;
    }

    .navbar__logo.white{
        display: none;
    }

    // EVENTBUTTON
    .eventButton{
        border: 1px solid $brand-primary;
        color: $brand-primary !important;
        background-color: rgba(255,255,255,1);
    }
}

//
// MOBILE NAVIGATION
// 

#mobile-navigation-overlay{
    position: fixed;
    background-color: $brand-primary;
    right: -100vw;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: $zindex-popover;
    transition: 0.3s ease right;

    .mobile-navigation-overlay-container{
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a{
            color: #fff;
            font-size: 2rem;
            position: relative;
        }

        .eventButton{
            font-size: 1.5rem !important;
        }

        a:hover, a:focus, a:active{
            text-decoration: none;
        }
    }
}


#mobile-navigation-overlay.active{
    right: 0px;
    top: 0px;
    transition: 0.3s ease right;
}
