/* ==========================================================================
    #VIDEOOVERLAY
========================================================================== */

.header_videooverlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    z-index: 999999999;
    background-color: black;

    display: flex;
    align-items: center;
    justify-content: center;

    display: none;

    .header_videooverlay__closebutton{
        width: 4rem;
        height: 4rem;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        right: 0;
    }
}

.header_videooverlay.active{
    display: flex;

    .video__container{
        width: 90vw;
        max-width: 1200px;
    }
}