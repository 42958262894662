/* ==========================================================================
    #HEADER
========================================================================== */


.header_big__container.home{
    &:hover{
        // cursor: pointer;

        span.icon-arrow{
            margin-left: 10px;
            transition: 0.5s ease all;
        }
    }
}


/* ==========================================================================
    #CASES
========================================================================== */

.grid__item-small.end-item{
    font-weight:  $font-weight-regular;
    background-color: $brand-primary !important;

    p{
        font-size: 30px;
        max-width: 80%;
        max-width: 200px;
        line-height: 120%;
        font-weight: $font-weight-regular;
    }
    strong{
        font-weight: $font-weight-black;
    }
}

@include mq($from: tablet) {
    .grid__item-small.end-item{
        p{
            font-size: 40px;
        }
    }
}

/* ==========================================================================
    #CLIENTS
========================================================================== */

.clients__carousel{
    // margin: 0 1% 1% 0;
    padding: 0;
    margin-bottom: 0px;

    // border-right: 20px solid $white;
    // border-bottom: 20px solid $white;

    // flex: 1 0 100%;

    .clients__carousel__innercontainer{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        margin-left: 0px;
    }
    .clients__carousel__item{
        list-style-type: none !important;
        margin-left: 0px;
        margin-right: 0px;
        // flex: 1 0 15%;
        margin-bottom: $inuit-global-spacing-unit-small;

        

        &:before{
            display: none;
        }
        
        a{
            // height: 2rem;
            max-width: 4rem;
            
            // border-right: 1% solid $white;
            // margin: $inuit-global-spacing-unit-tiny;

            &:empty {
                height: 0;
                border: none;
                padding: 0px;
            }

            img{
                max-width: 6rem;
                padding: 5px;
            }
        }
        
    }
}

@include mq($from: tablet) {
    .clients__carousel{
        .clients__carousel__item{
            flex: 0 0 20%;

            a{
                // height: 4rem;

                img{
                    max-width: 10rem;
                    padding-left: 15px;
                }
            }
        }
    }
}

@include mq($from: desktop) {
    .clients__carousel{
        .clients__carousel__item{
            flex: 0 0 15%;
        }
    }
}